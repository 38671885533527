import { useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";

import { useState as useHookState } from "@hookstate/core";

import Loader from "../Loader";

import BackButton from "../../images/back_arrow_icon@2x.png";
import {
  ComplaintStatus,
  GetComplaint,
  UpdateResidanceComplaint,
} from "../../Interfaces/Complaint";
import {
  GetComplaintManagers,
  GetComplaintStatuesQuery,
  GetResidantComplaint,
  UpdateResidentComplaint,
} from "../../Query/Complaints";

import ComplaintCard from "./complaintCard";

import * as ComplaintDetailsStyle from "./ComplaintDetails.module.scss";
import Logger from "../../utils/Logger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAlt,
  faMapMarkerAlt,
  faPhoneAlt,
  faUser,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { Manager } from "../../Interfaces/User";
import { SubmitHandler, useForm } from "react-hook-form";
import { popoverAlert } from "../../utils/Alert";
import AreaRoles from "../../States/AreaRoles";

interface Props {
  complaintId: string;
  areaId: string;
}

interface GetManagers {
  parkingAreaManagers: {
    collection: Manager[];
  };
}

interface Input {
  manager: string;
}

interface ResidanctComnplaintStatuses {
  residenceComplaintStatuses: {
    collection: {
      id: string;
      code: ComplaintStatus;
    }[];
  };
}

const Index = ({ complaintId, areaId }: Props) => {
  const { register, handleSubmit } = useForm();

  const areaRolesState = useHookState(AreaRoles).get();

  const [myRole, setMyRole] = useState<"owner" | "helper" | "helpdesk">();

  const {
    data: complaint,
    loading,
    error,
    refetch,
  } = useQuery<GetComplaint>(GetResidantComplaint, {
    variables: {
      id: `residence_complaints/${complaintId}`,
      order: [{ createdAt: "DESC" }],
    },
  });

  const { data: managers, refetch: refetchManagers } = useQuery<GetManagers>(
    GetComplaintManagers,
    {
      variables: {
        role: "ROLE_MANAGER",
        status: "ACTIVE",
        parkingArea: `/parking_areas/${areaId}`,
        itemsPerPage: 100,
      },
    }
  );

  const [
    updateResidanceComplaint,
    { error: updateResidentError, loading: updateResidentLoading },
  ] = useMutation<UpdateResidanceComplaint>(UpdateResidentComplaint);

  const { data: residantComplaintStatuses } =
    useQuery<ResidanctComnplaintStatuses>(GetComplaintStatuesQuery);

  const addComment: SubmitHandler<{ comment: string }> = (data) => {
    updateResidanceComplaint({
      variables: {
        id: complaint?.residenceComplaint?.id,
        status: complaint?.residenceComplaint?.status?.id,
        comment: data.comment,
      },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeStatus: SubmitHandler<{ status: string }> = ({ status }) => {
    if (status.length > 6) {
      updateResidanceComplaint({
        variables: {
          id: complaint?.residenceComplaint?.id,
          status: status,
          comment: "Status Changed",
        },
      })
        .then(() => {
          popoverAlert({
            icon: "success",
            title: "SUCCESS",
            msg: "Status Updated",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Logger.showError(new Error("Select Another Status"));
    }
  };

  const onChangeManager: SubmitHandler<Input> = (data) => {
    if (
      data.manager.length > 5 &&
      data.manager !== complaint?.residenceComplaint?.manager?.id
    ) {
      updateResidanceComplaint({
        variables: {
          id: complaint?.residenceComplaint?.id,
          manager: data.manager,
          status:
            residantComplaintStatuses?.residenceComplaintStatuses.collection.filter(
              ({ code }) => code === "REASSIGNED"
            )[0]?.id,
          comment: complaint?.residenceComplaint?.manager?.id
            ? "Manager Re-Assigned"
            : "Manager Assigned",
        },
      })
        .then(() => {
          popoverAlert({
            icon: "success",
            msg: "Manager Changed",
            title: "SUCCESS",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Logger.showError(new Error("Select Another Manager"));
    }
  };

  useEffect(() => {
    refetchManagers();
  }, []);

  useEffect(() => {
    const err = error || updateResidentError;
    if (err) Logger.showError(err);
  }, [error, updateResidentError]);

  useEffect(() => {
    const currentArea = areaRolesState.areas.filter(
      ({ areaId: id }) => id.split("/")[2] === areaId
    )[0];
    if (currentArea) {
      if (
        currentArea.role.includes("ROLE_PARTNER") &&
        currentArea.role.length === 1
      ) {
        setMyRole("owner");
      }
      if (currentArea.role.includes("ROLE_HELPER")) {
        setMyRole("helper");
      }
      if (currentArea.role.includes("ROLE_MANAGER_HELPDESK")) {
        setMyRole("helpdesk");
      }
    }
  }, [areaRolesState]);

  return (
    <>
      <div className={ComplaintDetailsStyle.container}>
        <div className={ComplaintDetailsStyle.header}>
          <button onClick={() => navigate(-1)}>
            <img src={BackButton} alt="go back" width={32} height={32} />
          </button>
        </div>
        <div className={ComplaintDetailsStyle.content}>
          <div className={ComplaintDetailsStyle.detail}>
            <div className={ComplaintDetailsStyle.item}>
              <span className={ComplaintDetailsStyle.item__text}>
                <FontAwesomeIcon icon={faUser} />
                <span>Raised By: </span>
              </span>
              <span>{complaint?.residenceComplaint?.user?.name}</span>
            </div>
            <div className={ComplaintDetailsStyle.item}>
              <span className={ComplaintDetailsStyle.item__text}>
                <FontAwesomeIcon icon={faPhoneAlt} />
                <span>Contact Number:</span>{" "}
              </span>
              <span>
                {complaint?.residenceComplaint?.user?.contactNumber || "N/A"}
              </span>
            </div>
            <div className={ComplaintDetailsStyle.item}>
              <span className={ComplaintDetailsStyle.item__text}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <span>Address:</span>
              </span>
              <span>
                {complaint?.residenceComplaint?.residence?.towerName},
                {complaint?.residenceComplaint?.residence?.flatName}
              </span>
            </div>
            {myRole !== "helper" && (
              <div className={ComplaintDetailsStyle.item}>
                <span className={ComplaintDetailsStyle.item__text}>
                  <FontAwesomeIcon icon={faUserCog} />
                  <span>Assigned To:</span>
                </span>

                <form
                  className={ComplaintDetailsStyle.assigne}
                  onSubmit={handleSubmit(onChangeManager)}
                >
                  {managers?.parkingAreaManagers?.collection && (
                    <>
                      <select
                        defaultValue={
                          complaint?.residenceComplaint?.manager?.id
                        }
                        {...register("manager")}
                      >
                        {!complaint?.residenceComplaint?.manager?.id && (
                          <option value="">Assigne</option>
                        )}
                        {managers?.parkingAreaManagers?.collection.map(
                          ({ id, manager }) => (
                            <option
                              selected={
                                complaint?.residenceComplaint?.manager?.id ===
                                manager?.id
                              }
                              value={manager?.id}
                              key={id}
                            >
                              {manager?.name}
                            </option>
                          )
                        )}
                      </select>
                      <button type="submit">
                        {complaint?.residenceComplaint?.manager?.id
                          ? "Reassign"
                          : "Assign"}
                      </button>
                    </>
                  )}
                </form>
              </div>
            )}

            <div className={ComplaintDetailsStyle.item}>
              <span className={ComplaintDetailsStyle.item__text}>
                <FontAwesomeIcon icon={faUserCog} />
                <span>Status</span>
              </span>

              <form
                className={ComplaintDetailsStyle.assigne}
                onSubmit={handleSubmit(onChangeStatus)}
              >
                {managers?.parkingAreaManagers?.collection && (
                  <>
                    <select
                      defaultValue={complaint?.residenceComplaint?.manager?.id}
                      {...register("status")}
                    >
                      {residantComplaintStatuses?.residenceComplaintStatuses?.collection
                        .filter(({ code }) => code !== "DELETED")
                        .map(({ id, code }) => (
                          <option
                            selected={
                              complaint?.residenceComplaint?.status?.id === id
                            }
                            value={id}
                            key={id}
                          >
                            {code}
                          </option>
                        ))}
                    </select>
                    <button type="submit">Change</button>
                  </>
                )}
              </form>
            </div>

            <div className={ComplaintDetailsStyle.item}>
              <span className={ComplaintDetailsStyle.item__text}>
                <FontAwesomeIcon icon={faCommentAlt} />
                <span>Description:</span>
              </span>
              <span>{complaint?.residenceComplaint?.description}</span>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(addComment)}
            className={ComplaintDetailsStyle.comment}
          >
            <div className={ComplaintDetailsStyle.comment__item}>
              <label htmlFor="comment">Comment</label>
              <textarea {...register("comment")} rows={10} required></textarea>
            </div>
            <div className={ComplaintDetailsStyle.comment__submit}>
              <button>Submit</button>
            </div>
          </form>
        </div>
        <div className={ComplaintDetailsStyle.conversation}>
          {complaint?.residenceComplaint?.residenceComplaintLogs?.collection.map(
            (comment) => (
              <ComplaintCard key={comment.id} comment={comment} />
            )
          )}
        </div>
      </div>
      <Loader isLoading={loading || updateResidentLoading} />
    </>
  );
};

export default Index;
