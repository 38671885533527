import React from "react";
import { ResidenceComplaintLogs } from "../../../Interfaces/Complaint";

import DefaultImg from "../../../images/profile-pic-icon.png";

import * as ComplaintCardStyle from "./complaintCard.module.scss";
import moment from "moment";

interface Props {
  comment: ResidenceComplaintLogs;
}

const Index = ({ comment }: Props) => {
  return (
    <div className={ComplaintCardStyle.card}>
      <img
        src={DefaultImg}
        alt="user"
        width={52}
        height={52}
        {...{
          style: {
            borderRadius: "100px",
          },
        }}
      />
      <div className={ComplaintCardStyle.card__content}>
        <span>{comment?.admin?.name}</span>
        <span>Status : {comment?.status?.code}</span>
        <span>
          Created At :{" "}
          {moment(comment?.createdAt).format("DD/MM/YYYY hh:mm:ss")}
        </span>
        <span>Comment: {comment?.comment}</span>
      </div>
    </div>
  );
};

export default Index;
