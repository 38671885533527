import React from "react";

import Layout from "../../../Components/Layout";
import ComplaintDetail from "../../../Components/ComplaintDetail";

const ComplaintDetailPage = ({ complaintid, areaid }) => {
  return (
    <Layout isPrivate showHeader>
      <ComplaintDetail complaintId={complaintid} areaId={areaid} />
    </Layout>
  );
};

export default ComplaintDetailPage;
