// extracted by mini-css-extract-plugin
export var container = "ComplaintDetails-module--container--dMh6R";
export var header = "ComplaintDetails-module--header--G8w9P";
export var content = "ComplaintDetails-module--content--9bE+L";
export var detail = "ComplaintDetails-module--detail--9oPHb";
export var item = "ComplaintDetails-module--item--+wATd";
export var item__text = "ComplaintDetails-module--item__text--FPyNh";
export var conversation = "ComplaintDetails-module--conversation--1Le6S";
export var comment = "ComplaintDetails-module--comment--oQPWq";
export var comment__submit = "ComplaintDetails-module--comment__submit--zaXNO";
export var comment__item = "ComplaintDetails-module--comment__item--euihI";
export var assigne = "ComplaintDetails-module--assigne--PWtV6";